<script>
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {http} from "../../../helpers/easyindustriaapi/config";

export default {
  page: {
    title: "Alterar Empresa",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props: {
    currentEmpresa: {},
    oldEmpresa: { type: Object },
    ibge: { type: Array }
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      editando: false,
      //  empresa: {},
      titleBody: 'Alterar Empresa',
      list2_cfop: [
        'one',
        'two',
        'tree'
      ],
      tipos: [
        'homologação',
        'produção'
      ],
      estados: [],
      diretorio:null,
      file:null


    }

  },
  created() {
    this.$emit('newTitle', this.titleBody);
    this.convert_citys();

  },
  mounted() {
    this.empresa = this.oldEmpresa;
    //this.setNewTitle();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    handleFileChange(event) {
      // Atualiza a variável 'diretorio' com o nome do arquivo selecionado

      this.diretorio = event.target.files[0].name;
      this.file =  event.target.files[0];
      console.log(this.file)

      //console.log(this.diretorio)
    },
    async post_enviar(event){
      event.preventDefault();
      
      let certificado = this.file
      certificado.empresa_id = this.currentEmpresa.id;
      certificado.user_id = this.currentUser.id;
      //console.log(banco);
      this.onLoader();

      try {
        let response = await http.post('/certificado?empresa_id='+this.currentEmpresa.id,certificado);
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast('success', 'certificado incluído');
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error);
          }
        }
      }
    },
    convert_citys() {
      this.ibge.forEach((cidade) => {

        //console.log(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        // if (!this.estados.includes(cidade.municipio.microrregiao.mesorregiao.UF.nome)){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        // }

        // if(cidade.municipio.microrregiao.mesorregiao.UF.nome
        //  != this.ibge[index+1]. municipio.microrregiao.mesorregiao.UF.nome ){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        //  }
        this.adicionarSemDuplicatas(cidade.municipio.microrregiao.mesorregiao.UF);

        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)

        //console.log(cidade.municipio.microrregiao.mesorregiao.UF);
      });

    },
    adicionarSemDuplicatas(novoObjeto) {
      if (!this.estados.some(objeto => objeto.nome === novoObjeto.nome)) {
        this.estados.push(novoObjeto);
      }
    },
    setNewTitle() {
      this.$emit("newTitle", 'Alterar Empresa ' + this.empresa.id);
    },
    // voltar () {
    //   this.inserindo = !this.inserindo;
    //   this.$emit('status', this.inserindo);
    //   this.$emit('voltar');
    // },
    // setAlterar () {
    //   this.inserindo = !this.inserindo;
    //   this.$emit("alterarEmpresa", this.empresa);
    // },
    setPut() {
      // chamo a funcãode atualização do index;
      this.$emit('doPut', this.empresa)

    },
    get_ibge() {

    },
    carregar_certificado() {
      // colocar o link do endpint aqui; 
    },
    voltar() {
      this.$emit('back')
    }
  },
}
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Iformações da empresa</b-button>
          </b-card-header>
          <b-collapse id="pedido-dados-gerais" visible accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3" id="cod_prod">
                  <div class="form-group">
                    <label for="cod_id" class="col-form-label">Código Produto</label>
                    <div class="col">
                      <input disabled class="form-control text-right" type="text" placeholder="Gerado pelo sistema"
                        id="cod_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="nome_id" class="col-form-label">Nome</label>
                    <div class="col">
                      <input class="form-control text-right" type="text" placeholder="Digite o Nome da Empresa"
                        id="nome_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="razao_id" class="col-form-label" id="CFOP_ID">Razão Social</label>
                    <div class="col" id="CFOP_ID">
                      <input disabled class="form-control text-right" type="text" placeholder="digite a razão social"
                        id="razao_id" />
                      <!-- <div v-if="submitted && $v.pedido.CFOP.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.CFOP.required">
                          {{ $t("mesages.validation.required") }}</span>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group" id="espaco1">
                    <label for="quatidade_id" class="col-form-label">CNPJ</label>
                    <div class="col">
                      <input type="number" class="form-control text-right" placeholder="Qauntidade" id="quantidade" />
                      <!-- <div v-if="submitted && $v.pedido.Quantidade.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Quantidade.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group" id="espaco">

                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="endereco" class="col-form-label">Endereço
                    </label>
                    <div class="col">
                      <input class="form-control text-right" placeholder="Digite o endereço" id="endereco_id" />
                      <!-- <div v-if="submitted && $v.pedido.Unitario.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Unitario.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="bairro" class="col-form-label">Bairro</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite o Bairro" id="bairro_id" />
                      <!-- <div v-if="submitted && $v.pedido.Desconto.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Desconto.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="numero" class="col-form-label">Número</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite o Numero" id="numero_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="complemento" class="col-form-label">Complemento</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite o complemento"
                        id="complemento_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="telefone" class="col-form-label">Telefone</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite o telefone"
                        id="telefone_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="cep" class="col-form-label">Cep</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite o Cep" id="cep_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="cidade" class="col-form-label">Cidade</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite a cidade" id="cidade_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="UF" class="col-form-label">UF</label>
                    <div class="col">
                      <Multiselect :options="estados" label="nome">

                      </Multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="insc_estadual" class="col-form-label">Inscrição Estadual</label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite a Inscrição estadual"
                        id="insc_estadual_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="insc_municipal" class="col-form-label">Inscrição Municipal </label>
                    <div class="col">
                      <input type="text" class="form-control text-right" placeholder="Digite a Inscrição municipal"
                        id="insc_municipal_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-complementares variant="light">Dados NFE</b-button>
          </b-card-header>
          <b-collapse id="pedido-complementares">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="regime" class="col-form-label">Regime</label>
                    <div class="col">
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="CSOSN" class="col-form-label">chave de acesso</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="CSOSN" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="Origem" class="col-form-label">Token Sefaz</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="CSOSN" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="tk_sefaz" class="col-form-label">ID Token Sefaz</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="tk_sefaz" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="base_icms" class="col-form-label">Tipo de Ambiente</label>
                    <div class="col">

                      <Multiselect :options="tipos">

                      </Multiselect>

                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="Reg_trib" class="col-form-label">Regime tributario</label>
                    <div class="col">
                      <Multiselect :options="tipos">

                      </Multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <!--- ------------ -->

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-ipi variant="light2">Certficado</b-button>
          </b-card-header>
          <b-collapse id="pdv-ipi">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="s_tributaria" class="col-form-label">Carregar Certificado no Computador</label>
                    <div class="col">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="customFile" @change="handleFileChange($event)">
                        <label class="custom-file-label" for="customFile">{{ diretorio || 'Selecionar arquivo' }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="cod_enq" class="col-form-label">Carregar Certificado No Banco</label>
                    <div class="col">
                      <button class="btn btn-light" @click="post_enviar($event)">
                        Carregar
                      </button>
                      <button class="btn btn-light ml-2 mr-2">
                        Ler configurações
                      </button>
                      <button class="btn btn-light">
                        Status
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="certificado" class="col-form-label">Senha</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="certificado_id" >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group"  role="group">
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light">
          Alterar Empresa
        </button>
      </div>
    </div>
  </div>
</template>